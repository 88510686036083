body,
html,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.logo {
  max-width: 90%;
  width: 300px;
  height: auto;
}
